import { useEffect, useMemo, useState } from "react";
import { LSUserData, renderErrorToast } from "../../utils/functions";
import Skeleton from "react-loading-skeleton";
import { ProductsStructure } from "../products-structure";
import { isAnExportOutlet, isASalesOrderCustomer } from "../../utils/constants";

export function quantityError(qty) {
  return renderErrorToast(
    "Available Quantity Exceeded",
    `Only ${qty} available in stock`,
    "qty-exceeded-err"
  );
}
export function SideProducts({
  syncProducts,
  collection = [],
  fetchingProducts = false,
  variants,
  selectedProducts,
}) {
  const { retailOutlets: [retailOutlet] = {} } = LSUserData();
  const [products, setProducts] = useState([...selectedProducts]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [searchText, setSearchText] = useState("");
  const [viewCollectionVariants, setViewCollectionVariants] = useState(false);

  useEffect(() => {
    setProducts([...selectedProducts]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProducts]);

  let categories = variants.map((el) => el.categoryId);
  categories = Array.from(new Set(categories));
  categories = categories.map((categoryid) => {
    const { categoryId, category } = variants.find(
      (el) => el.categoryId === categoryid
    );
    return { categoryId, name: category, type: "category" };
  });
  categories = categories.filter((el) => el.name);

  const categorizedCollection = collection.map((el, key) => ({
    name: el.name,
    collectionId: el._id,
    categoryId: `${el._id}_${key}`,
    type: "collection",
  }));

  if (viewCollectionVariants)
    categories = [...categories, ...categorizedCollection];

  const collectionVariants = collection.flatMap((collection) =>
    collection.productVariants.map((el) => ({
      ...el,
      collectionId: collection._id,
      collectionName: collection.name,
    }))
  );

  const variantsPerCategory = useMemo(() => {
    let allVariants = [...variants];
    if (viewCollectionVariants)
      allVariants = [...variants, ...collectionVariants];
    if (!Object.keys(selectedCategory).length) return allVariants;
    if (selectedCategory.type === "collection")
      return allVariants.filter(
        (el) =>
          String(selectedCategory.collectionId) === String(el.collectionId)
      );
    return allVariants.filter(
      (el) => String(el.categoryId) === String(selectedCategory.categoryId)
    );
  }, [selectedCategory, variants, viewCollectionVariants, collectionVariants]);

  const searchedProducts = useMemo(() => {
    return variantsPerCategory.filter((el) =>
      el.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [variantsPerCategory, searchText]);

  const handleRemoveProduct = (propsProduct) => {
    const productId = propsProduct?._id;
    if (!productId) return;
    const existingSelectedProductWithProductIdIndex = products.findIndex(
      (el) => el._id === productId
    );
    if (existingSelectedProductWithProductIdIndex < 0) return;
    const product = products[existingSelectedProductWithProductIdIndex];
    if (!product) return;
    const newCount = Number(product.count) - 1;
    if (!newCount) {
      const remainingProducts = products.filter((el) => el._id !== productId);
      return setProducts([...remainingProducts]);
    }
    products[existingSelectedProductWithProductIdIndex] = {
      ...product,
      count: newCount,
    };
    setProducts([...products]);
  };

  const handleAddProduct = (product) => {
    const productId = product?._id;
    if (!productId) return;
    const existingSelectedProductWithProductIdIndex = products.findIndex(
      (el) => el._id === productId
    );
    if (existingSelectedProductWithProductIdIndex < 0) {
      const product = variantsPerCategory.find((el) => el._id === productId);
      const initialCount = 1;
      const currentSupplyQty = product?.extvariants?.available;
      if (initialCount > currentSupplyQty)
        return quantityError(currentSupplyQty);
      setProducts([...products, { ...product, count: initialCount }]);
    } else {
      const product = products.find((el) => el._id === productId);
      const count = Number(product.count) + 1;
      const currentSupplyQty = product?.extvariants?.available;
      if (count > currentSupplyQty) return quantityError(currentSupplyQty);
      products[existingSelectedProductWithProductIdIndex] = {
        ...product,
        count,
      };
      setProducts([...products]);
    }
  };

  const handleInputProductQuantity = (productId, value) => {
    if (value < 1) return;
    const existingSelectedProductWithProductIdIndex = products.findIndex(
      (el) => el._id === productId
    );
    if (existingSelectedProductWithProductIdIndex < 0) return;
    const product = products[existingSelectedProductWithProductIdIndex];
    const currentSupplyQty = product?.extvariants?.available;
    if (currentSupplyQty > Number(value)) {
      products[existingSelectedProductWithProductIdIndex] = {
        ...product,
        count: Number(value),
      };
      return setProducts([...products]);
    }
    return quantityError(currentSupplyQty);
  };

  return (
    <div
      className='offcanvas offcanvas-end tdp_offcanvas'
      tabindex='-1'
      id='offcanvasExample'
      aria-labelledby='offcanvasExampleLabel'
    >
      <div className='offcanvas-header'>
        <h5 className='offcanvas-title' id='offcanvasExampleLabel'>
          <i
            className='bi bi-x-lg'
            data-bs-dismiss='offcanvas'
            aria-label='Close'
            id='close'
          ></i>
          Products
        </h5>
        <button
          onClick={() => {
            syncProducts(products);
            document.getElementById("close").click();
          }}
        >
          Save
        </button>
      </div>
      <div className='offcanvas-header header-2'>
        <input
          className='form-control'
          placeholder='Search'
          onChange={({ target: { value } }) => setSearchText(value)}
        />
        <select
          className='form-select'
          onChange={({ target: { value } }) => {
            if (value === "All") setSelectedCategory({});
            else
              setSelectedCategory(
                categories.find((el) => el.categoryId === value)
              );
          }}
        >
          <option disabled selected>
            Category
          </option>
          <option>All</option>
          {categories.map((el, key) => (
            <option key={key} value={el.categoryId}>
              {el.name}
            </option>
          ))}
        </select>
      </div>
      {fetchingProducts ? (
        <LoadingCategories />
      ) : (
        <div className='offcanvas-header header-3'>
          {categories.map((el, key) => (
            <div
              className={
                selectedCategory.categoryId === el.categoryId ? "selected" : ""
              }
              key={key}
              onClick={() =>
                selectedCategory?.categoryId &&
                selectedCategory.categoryId === el.categoryId
                  ? setSelectedCategory({})
                  : setSelectedCategory(el)
              }
            >
              <p key={key}>{el.name}</p>
            </div>
          ))}
        </div>
      )}

      <div className='offcanvas-body'>
        <div className='tdp_product_catalog'>
          {fetchingProducts ? (
            <LoadingProduct />
          ) : searchedProducts.length ? (
            <>
              {searchedProducts.filter((el) => !el.collectionName).length ? (
                <>
                  <h2>Your Listings</h2>
                  <ProductsStructure
                    products={searchedProducts.filter(
                      (el) => !el.collectionName
                    )}
                    cartProducts={products}
                    handleAddProduct={handleAddProduct}
                    handleInputProductQuantity={handleInputProductQuantity}
                    handleRemoveProduct={handleRemoveProduct}
                  />
                </>
              ) : null}
              {searchedProducts.filter((el) => el.collectionName).length ? (
                <>
                  <h2>Other Products</h2>
                  <ProductsStructure
                    products={searchedProducts.filter(
                      (el) => el.collectionName
                    )}
                    handleAddProduct={handleAddProduct}
                    handleInputProductQuantity={handleInputProductQuantity}
                    handleRemoveProduct={handleRemoveProduct}
                    cartProducts={products}
                  />
                </>
              ) : null}
            </>
          ) : (
            <ProductEmptyList
              searchText={searchText}
              selectedCategory={selectedCategory}
            />
          )}
        </div>
      </div>

      {viewCollectionVariants ||
      isAnExportOutlet(retailOutlet) ||
      isASalesOrderCustomer(retailOutlet) ? null : (
        <div className='offcanvas-footer'>
          <p
            disabled={fetchingProducts}
            onClick={() => {
              if (fetchingProducts) return;
              setViewCollectionVariants(!viewCollectionVariants);
            }}
          >
            {fetchingProducts ? (
              "Loading Products, please wait..."
            ) : (
              <>
                See more products <i class='bi bi-chevron-right'></i>
              </>
            )}
          </p>
        </div>
      )}
    </div>
  );
}

export function LoadingCategories() {
  return (
    <div className='tdp_products__loading-categories'>
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </div>
  );
}

export function LoadingProduct({ length = 8 }) {
  const arr = [...Array(length).keys()].map((i) => i + 1);
  return arr.map((el, key) => (
    <div className='tdp_products__loading-variants' key={key}>
      <Skeleton />
      <div className=''>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
    </div>
  ));
}

export function ProductEmptyList({ searchText = "", selectedCategory = null }) {
  return (
    <div className='products_empty'>
      <svg
        width='37'
        height='37'
        viewBox='0 0 37 37'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M12.125 12.125H29.375L26.9192 22.5621C26.6003 23.9173 25.3911 24.875 23.999 24.875H17.8092C16.448 24.875 15.2574 23.9586 14.9091 22.6427L12.125 12.125ZM12.125 12.125L11 7.625H7.625'
          stroke='#081F24'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M16.25 29C16.25 29.4142 15.9142 29.75 15.5 29.75C15.0858 29.75 14.75 29.4142 14.75 29C14.75 28.5858 15.0858 28.25 15.5 28.25C15.9142 28.25 16.25 28.5858 16.25 29Z'
          stroke='#081F24'
        />
        <path
          d='M26.75 29C26.75 29.4142 26.4142 29.75 26 29.75C25.5858 29.75 25.25 29.4142 25.25 29C25.25 28.5858 25.5858 28.25 26 28.25C26.4142 28.25 26.75 28.5858 26.75 29Z'
          stroke='#081F24'
        />
      </svg>

      <h3>
        {searchText || selectedCategory?.categoryId
          ? "No products available"
          : "No items for your location"}
      </h3>
      <h6>
        {searchText || selectedCategory?.categoryId
          ? "There are currently no products matching your search in inventory"
          : "We’re expanding quickly so please check back soon."}
      </h6>
    </div>
  );
}
