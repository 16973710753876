import { useEffect, useState } from "react";
import {
  capitalizeStatusText,
  errorHandler,
  formatMoney,
  handlePrintOrder,
  LSUserData,
  renderErrorToast,
  scrollBackToTop,
} from "../utils/functions";
import DatePicker from "react-datepicker";
import { CalendarIcon } from "../assets/calendarsvg";
import { Orders as OrdersSvg } from "../assets/orders";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { relativeStatus } from "../utils/constants";
import moment from "moment";
import { Loader } from "../components/loader";
import { Pagination } from "../components/pagination";

export function SalesOrders() {
  const { apiKey } = LSUserData() || {};
  const [orderStatus, setOrderStatus] = useState("all");
  const [selectedDates, setSelectedDates] = useState([]);
  const [orders, setOrders] = useState([]);
  const [pagination, setPagination] = useState({ totalCount: 0 });
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [selectedOrder, setSelectedOrder] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [printing, setPrinting] = useState(false);

  console.log({ searchText, selectedOrder });

  const hasFilter = true;

  useEffect(() => {
    handleFetchSalesOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderStatus, !!selectedDates[1], currentPage]);

  const handleFetchSalesOrders = async () => {
    try {
      setLoadingScreen(true);
      const filters = {};
      if (orderStatus && orderStatus !== "all") {
        if (orderStatus === "pending") filters.status = "open";
        else if (orderStatus === "completed") filters.status = "shipped";
        else filters.status = orderStatus;
      }
      if (selectedDates && selectedDates.length === 2) {
        filters.startDate = selectedDates[0];
        if (selectedDates[1])
          filters.endDate = moment(selectedDates[1]).endOf("day");
      }
      const queryString = new URLSearchParams(filters).toString();
      const {
        data: {
          data: { orders, pagination },
        },
      } = await axios.get(
        `${process.env.REACT_APP_TD_CONSOLE_URL}/api/v3/sales/orders?${queryString}&page=${currentPage}`,
        {
          headers: {
            "x-api-key": apiKey,
          },
        }
      );
      setOrders(orders);
      setPagination({
        ...pagination,
        totalCount: pagination.totalPages * pagination.perPage,
        page: Number(pagination.page),
      });
      setLoadingScreen(false);
    } catch (error) {
      const {
        message = "There was an issue with fetching sales orders. Please try again later.",
      } = errorHandler(error);
      setLoadingScreen(false);
      renderErrorToast("Something went wrong!", message, "error-sales-order");
    }
  };

  const handlePrintOrderCall = async (order) => {
    try {
      // e.stopPropagation();
      setPrinting(true);
      if (printing) return;
      await handlePrintOrder(order, setPrinting, setSelectedOrder);
    } catch (error) {
      setPrinting(false);
    }
  };

  return (
    <div className='tdp_orders tdp_invoices'>
      <div className='header page-header'>
        <h2>Sales Orders</h2>
        <div className='_filter_status'>
          <div className='order_statuses'>
            <button
              className={orderStatus === "all" ? "active" : ""}
              onClick={() => {
                setOrderStatus("all");
                scrollBackToTop();
              }}
            >
              All orders
            </button>
            <button
              className={orderStatus === "pending" ? "active" : ""}
              onClick={() => {
                setOrderStatus("pending");
                scrollBackToTop();
              }}
            >
              Pending
            </button>
            <button
              className={orderStatus === "completed" ? "active" : ""}
              onClick={() => {
                setOrderStatus("completed");
                scrollBackToTop();
              }}
            >
              Completed
            </button>
          </div>
          <div className='__filters'>
            <div className='date-picker-container'>
              <DatePicker
                placeholderText='Filter Date'
                monthsShown={2}
                onInputClick={() => setSelectedDates([])}
                onChange={(dates) => {
                  setSelectedDates(dates);
                  scrollBackToTop();
                }}
                startDate={selectedDates[0]}
                endDate={selectedDates[1]}
                selectsRange
                dateFormat='MMM d'
              />
              <CalendarIcon />
            </div>
          </div>
        </div>
      </div>
      <div className='body page-body list-body'>
        {
          <div
            className={`__input-search ${
              !loadingScreen && !orders.length ? "empty" : ""
            }`}
          >
            <i className='bi bi-search'></i>
            <input
              className='form-control order-search'
              placeholder='Type to search by reference number or destination'
              onChange={({ target: { value } }) => {
                setSearchText(value);
                scrollBackToTop();
              }}
            />
          </div>
        }
        <div className='orders-list'>
          {loadingScreen ? (
            <div className='screen-loader-wrapper'>
              <Skeleton count={10} />
            </div>
          ) : !loadingScreen && !orders.length ? (
            <div className='empty__list'>
              <OrdersSvg />
              <h3>
                {hasFilter && orderStatus !== "all"
                  ? `No ${orderStatus} orders available`
                  : hasFilter
                  ? "No orders matching your filter criteria"
                  : "No Orders Yet!"}
              </h3>
              <p onClick={() => navigate("/create-sales-order")}>
                Create a new sales order <i className='bi bi-arrow-right'></i>
              </p>
            </div>
          ) : !loadingScreen ? (
            <div className='entities'>
              <table className='table customer-portal-table'>
                <thead>
                  <tr>
                    <th scope='col' className='pd-left-30' colSpan={1}>
                      Amount
                    </th>
                    <th scope='col' colSpan={1}>
                      Summary
                    </th>
                    <th scope='col'>Delivering to</th>
                    <th scope='col'>Created On</th>
                    <th scope='col' colSpan={2}></th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order, key) => (
                    <tr
                      key={key}
                      onClick={() =>
                        navigate(`/console/sales-order/${order._id}`, {
                          state: {
                            isDraft: order.status === "pending",
                            draftOrder:
                              order.status === "pending" ? order : null,
                          },
                        })
                      }
                    >
                      <td className='amount' colSpan={1}>
                        <p>
                          <span>
                            {order?.currency?.symbol}
                            {formatMoney(order?.total || 0)}
                          </span>
                          <span>{order?.currency?.iso || "-"}</span>
                        </p>
                      </td>
                      <td colSpan={1}>
                        <span className='summary'>
                          {order?.summary ||
                            order?.items?.map(
                              (el, key) =>
                                `${el.name} ${order.items[key + 1] ? ", " : ""}`
                            )}
                        </span>
                      </td>
                      <td>
                        <span>{order?.customerName || "-"}</span>
                      </td>
                      <td className='createdAt'>
                        <span>{moment(order.createdAt).format("Do MMM")}</span>
                        {orderStatus === "all" ? (
                          <span
                            className={`custom_status ${relativeStatus(
                              order.status === "pending"
                                ? "draft"
                                : order.status === "open"
                                ? "pending"
                                : order.status
                            )}`}
                          >
                            {order.status === "pending"
                              ? "Draft"
                              : order.status === "open"
                              ? "Pending"
                              : capitalizeStatusText(order.status)}
                          </span>
                        ) : null}
                      </td>
                      <td
                        onClick={(e) => e.stopPropagation()}
                        colSpan={2}
                        className='actions-td'
                      >
                        <span
                          role='button'
                          className={`action-row-button ${
                            printing ? "disabled" : ""
                          }`}
                          onClick={() => handlePrintOrderCall(order)}
                        >
                          {printing ? <Loader /> : "Print Order"}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                dataCount={pagination.totalCount}
                perPage={pagination?.perPage}
                setCurrentPage={(page) => setCurrentPage(page)}
                currentPage={currentPage}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
