import { useNavigate } from "react-router";
import { SearchModal } from "./modals/search.modal";
// import { CustomSelect } from "./custom-select";
import // LOCAL_SELECTED_CURRENCY,
// SUPPORTED_CURRENCIES,
"../utils/constants";
import { AccountSettings } from "./drawers/account.settings.drawer";
import { LSUserData } from "../utils/functions";
import { isASalesOrderCustomer } from "../utils/constants";
// import { LSUserData } from "../utils/functions";

export function AuthedHeader({
  showCurrencyDropdown,
  setShowCurrencyDropdown,
  selectedCurrency,
  setSelectedCurrency,
}) {
  const navigate = useNavigate();
  const { retailOutlets: [retailOutlet] = [] } = LSUserData() || {};
  // const country = userData?.retailOutlets?.[0]?.country || "";

  return (
    <>
      <div
        className='tdp_authed_header'
        onClick={() => setShowCurrencyDropdown(false)}
      >
        <div className='header_props'>
          <div className='search-div'>
            <input
              className='form-control'
              placeholder='Search'
              data-bs-toggle='modal'
              data-bs-target='#searchModal'
            />
          </div>
          {/* {country !== "NG" ? (
            <div className=''>
              <CustomSelect
                open={showCurrencyDropdown}
                setOpen={(e) => {
                  e.stopPropagation();
                  setShowCurrencyDropdown(!showCurrencyDropdown);
                }}
                disabled
                items={SUPPORTED_CURRENCIES}
                action={({ title, value }) => {
                  console.log({ title, value });
                  setSelectedCurrency(title);
                  localStorage.setItem(LOCAL_SELECTED_CURRENCY, title);
                  setShowCurrencyDropdown(false);
                }}
                valueToDisplay={selectedCurrency}
              />
            </div>
          ) : null} */}
          <div className='_flex__ender'>
            <p
              data-bs-toggle='offcanvas'
              href='#offcanvasAccountSettings'
              role='button'
              aria-controls='offcanvasAccountSettings'
            >
              <img
                src='/images/icons/settings.svg'
                alt='settings-svg'
                className='img-fluid'
              />
            </p>
            <p>
              <img
                src='/images/icons/bell.svg'
                alt='bell-svg'
                className='img-fluid'
              />
            </p>
            {isASalesOrderCustomer(retailOutlet) ? (
              <button data-bs-toggle='dropdown' aria-expanded='false'>
                New Order
                <ul class='dropdown-menu'>
                  <a
                    className='dropdown-item'
                    onClick={() => window.location.replace("/create-order")}
                    href='/create-order'
                  >
                    <li>
                      <p>Create Purchase Order</p>
                    </li>
                  </a>
                  <a
                    class='dropdown-item'
                    onClick={() =>
                      window.location.replace("/create-sales-order")
                    }
                    href='/create-sales-order'
                  >
                    <li>
                      <p>Create Sales Order</p>
                    </li>
                  </a>
                </ul>
              </button>
            ) : (
              <button onClick={() => navigate("/create-order")}>
                New order
              </button>
            )}
          </div>
        </div>
        {/* <div className='row'>
          <div className='col-7'>
            <input
              className='form-control'
              placeholder='Search'
              data-bs-toggle='modal'
              data-bs-target='#searchModal'
            />
          </div>
          <div className='col-1'>
            <div className='tdp_console'>
              <div className='_two header'>
                <CustomSelect
                  open={showCurrencyDropdown}
                  setOpen={(e) => {
                    e.stopPropagation();
                    setShowCurrencyDropdown(!showCurrencyDropdown);
                  }}
                  items={[
                    { title: "USD ($)" },
                    { title: "EUR (£)" },
                    { title: "GBP (3)" },
                    { title: "NGN (N)" },
                  ]}
                  action={({ title }) => {
                    setSelectedCurrency(title);
                    setShowCurrencyDropdown(false);
                  }}
                  valueToDisplay={selectedCurrency}
                />
              </div>
            </div>
          </div>
          <div className='col-2 _flex__ender'>
            <p>
              <img
                src='/images/icons/settings.svg'
                alt='settings-svg'
                className='img-fluid'
              />
            </p>
            <p>
              <img
                src='/images/icons/bell.svg'
                alt='bell-svg'
                className='img-fluid'
              />
            </p>
            <button onClick={() => navigate("/create-order")}>New order</button>
          </div>
        </div> */}
      </div>
      <SearchModal />
      <AccountSettings />
    </>
  );
}
