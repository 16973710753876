export function InfoSvg() {
  return (
    <svg
      width='31'
      height='31'
      viewBox='0 0 31 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M24.5625 15.1429C24.5625 20.1479 20.5051 24.2054 15.5 24.2054C10.4949 24.2054 6.4375 20.1479 6.4375 15.1429C6.4375 10.1378 10.4949 6.08035 15.5 6.08035C20.5051 6.08035 24.5625 10.1378 24.5625 15.1429Z'
        fill='#081F24'
        stroke='#081F24'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M15.5 16.3929V18.8929'
        stroke='white'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M16.125 11.3929C16.125 11.738 15.8452 12.0179 15.5 12.0179C15.1548 12.0179 14.875 11.738 14.875 11.3929C14.875 11.0477 15.1548 10.7679 15.5 10.7679C15.8452 10.7679 16.125 11.0477 16.125 11.3929Z'
        fill='white'
        stroke='white'
      />
    </svg>
  );
}
