import { CalendarIcon } from "../assets/calendarsvg";
import DatePicker from "react-datepicker";
import { Pagination } from "../components/pagination";
import {
  capitalizeStatusText,
  errorHandler,
  LSUserData,
  renderErrorToast,
  scrollBackToTop,
} from "../utils/functions";
import { Orders as OrdersSvg } from "../assets/orders";
import { isAnExportOutlet, relativeStatus } from "../utils/constants";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ShipmentViewModal } from "../components/drawers/shipment.modal.view";

export function Shipments() {
  const { apiKey, retailOutlets } = LSUserData() || {};
  const [shipments, setShipments] = useState([]);
  const [selectedShipment, setSelectedShipment] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]);
  const [shipmentStatus, setShipmentStatus] = useState("all");
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({
    totalCount: 0,
    currentPage: 1,
    perPage: 10,
  });
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [hasFilter] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAnExportOutlet(retailOutlets?.[0])) return navigate("/console");
    handleFetchShipments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination?.currentPage, shipmentStatus]);

  useEffect(() => {
    if (!searchText) return;
    const runFunc = setTimeout(async () => {
      handleFetchShipments();
    }, 2000);
    return () => clearTimeout(runFunc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const handleFetchShipments = async () => {
    // Fetch shipments data here
    try {
      let url = `${process.env.REACT_APP_TD_CONSOLE_URL}/api/v3/procurement/get_shipments?perPage=${pagination?.perPage}&page=${pagination?.currentPage}`;
      if (shipmentStatus && shipmentStatus !== "all")
        url += `&shippingStatus=${shipmentStatus}`;
      if (searchText && searchText.length)
        url += `&shipmentNumber=${searchText}`;
      setLoadingScreen(true);
      const {
        data: { data, page, total },
      } = await axios.get(url, { headers: { "x-api-key": apiKey } });
      setPagination({
        ...pagination,
        totalCount: total,
        currentPage: page,
      });
      setShipments(data);
    } catch (error) {
      const {
        message = "We are unable to return shipments at this time, please try again later",
      } = errorHandler(error);
      renderErrorToast(
        "Something went wrong!",
        message,
        "fetching-shipments-err"
      );
    } finally {
      setLoadingScreen(false);
    }
  };

  return (
    <div className='tdp_orders tdp_invoices'>
      <div className='header page-header'>
        <h2>Shipments</h2>
        <div className='_filter_status'>
          <div className='order_statuses'>
            <button
              className={shipmentStatus === "all" ? "active" : ""}
              onClick={() => {
                setShipmentStatus("all");
                scrollBackToTop();
              }}
            >
              All shipments
            </button>
            {["Pending", "Processing", "Dispatched", "Delivered"].map((el) => (
              <button
                className={shipmentStatus === el.toLowerCase() ? "active" : ""}
                onClick={() => {
                  setShipmentStatus(el.toLowerCase());
                  scrollBackToTop();
                }}
              >
                {el}
              </button>
            ))}
          </div>
          <div className='__filters'>
            <div className='date-picker-container'>
              <DatePicker
                placeholderText='Filter Date'
                monthsShown={2}
                onInputClick={() => setSelectedDates([])}
                onChange={(dates) => {
                  setSelectedDates(dates);
                  scrollBackToTop();
                }}
                startDate={selectedDates[0]}
                endDate={selectedDates[1]}
                selectsRange
                dateFormat='MMM d'
                popperPlacement='bottom-end'
              />
              <CalendarIcon />
            </div>
          </div>
        </div>
      </div>
      <div className='body page-body list-body'>
        {shipmentStatus && shipmentStatus === "all" ? (
          <div className='__input-search'>
            <i className='bi bi-search'></i>
            <input
              className='form-control order-search'
              placeholder='Type to search by shipment number'
              onChange={({ target: { value } }) => {
                setSearchText(value);
                scrollBackToTop();
              }}
            />
          </div>
        ) : null}
        <div className='orders-list'>
          {loadingScreen ? (
            <div className='screen-loader-wrapper'>
              <Skeleton count={10} />
            </div>
          ) : !loadingScreen && !shipments.length ? (
            <div className='empty__list'>
              <OrdersSvg />
              <h3>
                {hasFilter && shipmentStatus !== "all"
                  ? `No ${shipmentStatus} shipments available`
                  : hasFilter
                  ? "No shipments matching your filter criteria"
                  : "No Shipments Yet!"}
              </h3>
              <p onClick={() => navigate("/create-order")}>
                Create a new order <i className='bi bi-arrow-right'></i>
              </p>
            </div>
          ) : !loadingScreen ? (
            <div className='entities'>
              <table class='table customer-portal-table'>
                <thead>
                  <tr>
                    <th scope='col' colSpan={1}>
                      Shipment number
                    </th>
                    <th scope='col' className='center'>
                      Date
                    </th>
                    <th scope='col' className='center'>
                      Status
                    </th>
                    <th scope='col' colSpan={2} className='center'>
                      Invoice numbers
                    </th>
                    <th scope='col' colSpan={2}></th>
                  </tr>
                </thead>
                <tbody>
                  {shipments.map((shipment, key) => (
                    <tr key={key}>
                      <td colSpan={1}>{shipment?.shipmentNumber || "-"}</td>
                      <td className='center'>
                        {moment(shipment?.createdAt).format("MM/DD/YYYY")}
                      </td>
                      <td className='center'>
                        <span
                          className={`custom_status ${relativeStatus(
                            shipment.shippingStatus === "pending"
                              ? "draft"
                              : shipment.shippingStatus === "open"
                              ? "pending"
                              : shipment.shippingStatus
                          )}`}
                        >
                          {capitalizeStatusText(shipment.shippingStatus)}
                        </span>
                      </td>
                      <td className='center' colSpan={2}>
                        {shipment?.invoices
                          ?.map((el) => el?.invoiceNumber)
                          .join(", ") || "-"}
                      </td>
                      <td colSpan={2}>
                        <a
                          data-bs-toggle='offcanvas'
                          href='#shipmentOffCanvasExample'
                          role='button'
                          aria-controls='shipmentOffCanvasExample'
                          className='view'
                          onClick={() => setSelectedShipment(shipment)}
                        >
                          View
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                dataCount={pagination.totalCount}
                perPage={pagination?.perPage}
                setCurrentPage={(page) =>
                  setPagination({ ...pagination, currentPage: page })
                }
                currentPage={pagination?.currentPage}
              />
            </div>
          ) : null}
        </div>
      </div>
      <ShipmentViewModal
        shipment={selectedShipment}
        setShipment={setSelectedShipment}
      />
    </div>
  );
}
